<template>
  <div class="md-content md-table-content Client responsive">
    <loading :active="app.showLoader.value" :can-cancel="true"></loading>
    <md-table
      :value="
        list.pages[listInput.page] && list.pages[listInput.page].items
          ? list.pages[listInput.page].items
          : []
      "
      class="globe-activity md-table-global-sales"
    >
      <!-- md-fixed-header -->
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="ORG Code">{{ item.code }}</md-table-cell>
        <md-table-cell md-label="Client Name">{{
          item.clientName
        }}</md-table-cell>
        <md-table-cell md-label="Contact Name">{{
          item.contactName
        }}</md-table-cell>
        <md-table-cell md-label="Business Name">{{
          item.businessName
        }}</md-table-cell>

        <md-table-cell md-label="Business Type">{{
          getBusinessType(item.businessType)
        }}</md-table-cell>
        <md-table-cell md-label="Domain"
          >https://{{ item.domain }}</md-table-cell
        >
        <md-table-cell md-label="Action">
          <md-icon @click.native="handleEdit(item)">edit</md-icon>
          <md-icon
            class="delete-icon"
            @click.native="deleteOrganisation(item.clientID)"
            >delete</md-icon
          >
        </md-table-cell>
      </md-table-row>
    </md-table>
  </div>
</template>
<script>
export default {
  name: "ClientListTable",
  inject: [
    "listInput",
    "list",
    "refreshList",
    "deleteOrganisation",
    "master",
    "app",
  ],
  data() {
    return {};
  },
  methods: {
    handleEdit(item) {
      this.$router.push(this.$route.path + "/edit/" + item.clientID);
    },
    getBusinessType(busiType) {
      var bType = this.master.businessType.find((x) => x.lookupId == busiType);
      //console.log(bType);
      if (bType != null) return bType.displayValue;
    },
  },
};
</script>
<style lang="scss" scoped>
.globe-activity {
  height: calc(100vh - 400px);
  overflow: auto;
}
.md-table-cell {
  // padding: 2px 8px;
  font-size: 0.8rem;
  height: 45px;
}
.md-table-row {
  color: rgb(0, 6, 97);
}
.md-table-global-sales .md-table-row .md-table-cell:first-child {
  font-weight: 500;
}
.Client.responsive td:nth-of-type(1):before {
  content: "ORG Code";
}
.Client.responsive td:nth-of-type(2):before {
  content: "Client Name";
}
.Client.responsive td:nth-of-type(3):before {
  content: "Contact Name";
}
.Client.responsive td:nth-of-type(4):before {
  content: "Business Name";
}
.Client.responsive td:nth-of-type(5):before {
  content: "Business Type";
}
.Client.responsive td:nth-of-type(6):before {
  content: "Domain";
}
.Client.responsive td:nth-of-type(7):before {
  content: "Action";
}
</style>
