<template>
  <div>
    <div>
      <div class="md-layout">
        <list-layout>
          <template v-slot:card-title> ClientS </template>
          <template v-slot:header-actions>
            <primary-button
              toolTip="Add New Client"
              style="margin-right: 15px"
              @click="handleAddClick()"
              >Add
            </primary-button>
          </template>
          <template v-slot:content>
            <Client-list-table />
          </template>
        </list-layout>
      </div>
    </div>
  </div>
</template>

<script>
import ListLayout from "../../components/layouts/list-layout/ListLayout.vue";
import ClientListTable from "./ClientListTable.vue";

export default {
  name: "ClientList",
  components: {
    ListLayout,
    ClientListTable,
  },
  methods: {
    handleAddClick() {
      this.$router.push("/globe-chek/clients/add");
    },
  },
};
</script>
